import './ds-currency-dropdown.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { ToastService } from 'services/toast-service';
import { getAWSBucketEndpoint } from 'environment';
import { WebsiteService } from 'services/website-service';
import { PaymentMethodWebsite } from 'services/models/purchase-flow/paymentMethodWebsite';
import { Currency } from 'services/models/purchase-flow/exchange';
import { IpAddressService } from 'services/ip-address-service';

@autoinject()
export class DsCurrencyDropdown {
    constructor(private sessionService: SessionService, private eventAggregator: EventAggregator, private router: Router, private toastService: ToastService, private websiteService: WebsiteService, private ipAddressService: IpAddressService) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
    }

    @bindable currencyOptions = [];
    baseAwsEndpoint: string;
    notification;
    preferredCurrency: any = { value: 'USD', id: 1 };
    forceCurrencySubscriber;
    currencySubscriber;
    sizeChanged;
    availableCurrencies;
    paymentMethodSelected: string;
    paymentMethodSelectedObject: PaymentMethodWebsite;
    pages;
    width;

    async attached() {
        try {
            if (!this.sessionService.geolocation) await this.ipAddressService.getIpData();
            const currentCurrency = await this.sessionService.getCurrency();
            this.pages = await this.websiteService.getPagesByWebsiteShortcode();
            this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (this.currencyOptions) {
                this.preferredCurrency = this.currencyOptions.find(currency => {
                    return currency.value === currentCurrency;
                });
            }
            if (!this.preferredCurrency) {
                this.sessionService.saveCurrency('USD');
                this.preferredCurrency = this.currencyOptions[0];
            }
        } catch (e) {
            console.log(e);
        }
        await this.setCurrency(this.preferredCurrency, null, false);
        this.handleEventSubscriptions();
    }

    detached() {
        this.forceCurrencySubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.forceCurrencySubscriber = this.eventAggregator.subscribe('force-currency', payload => {
            if (payload.currentPaymentMethodSelected !== null) {
                this.paymentMethodSelected = payload.currentPaymentMethodSelected.reference;
                this.paymentMethodSelectedObject = payload.currentPaymentMethodSelected;
                const currencyOption = this.sessionService.getDesiredCurrency(payload.currency);
                this.setCurrency(currencyOption, payload.oldCurrency, false);
            } else {
                this.paymentMethodSelected = payload.currentPaymentMethodSelected.reference ?? null;
            }
        });

        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async(payload) => {
            if (this.width === payload.width) return;
            this.width = payload.width;
        });
    }

    async setCurrency(currency: Currency, oldCurrency: Currency | string, fromCurrencyDropdown: boolean) {
        let correctCurrency = '';
        oldCurrency && typeof oldCurrency === 'object' ? oldCurrency = oldCurrency.value : oldCurrency;
        if (this.paymentMethodSelectedObject && (this.router.currentInstruction.config.route.includes('cart') || this.router.currentInstruction.fragment === `/${this.pages.find(x => x.name === 'Customer Portal')?.routeName ?? 'customer-portal'}/balance`) && this.paymentMethodSelectedObject.supportedCurrencies?.length) {
            const currencies = this.paymentMethodSelectedObject.supportedCurrencies.map(c => this.currencyOptions.find(ac => ac.id === c.currencyId)?.value);
            if (!currencies.includes(oldCurrency)) {
                currency = this.sessionService.getDesiredCurrency(currencies[0]);
                correctCurrency = currencies.join(', ');
            }
            if (correctCurrency !== '' && this.width >= 1200) {
                await this.toastService.showToast('Info', `${this.paymentMethodSelectedObject.paymentMethod.name} can only be paid in ${correctCurrency}. We've changed the checkout currency to reflect your payment method selection.`, 'info');
            }
        }
        oldCurrency = this.sessionService.getDesiredCurrency(oldCurrency);
        this.preferredCurrency = (fromCurrencyDropdown && correctCurrency === '') || !currency ? oldCurrency : currency;
        await this.sessionService.saveCurrency(this.preferredCurrency?.value);
        if (oldCurrency && correctCurrency === '') {
            await this.sessionService.savePreviousCurrency(oldCurrency as string);
        }
        this.availableCurrencies = [...this.currencyOptions];
        const selectedOptionIndex = this.currencyOptions.findIndex(selectedCurrency => {
            return selectedCurrency.value === this.preferredCurrency.value;
        });
        if (selectedOptionIndex >= 0) {
            this.availableCurrencies.splice(selectedOptionIndex, 1);
        }
    }
}
