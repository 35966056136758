import './ds-try-again-box.scss';
import { inject, bindable } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { WebsiteService } from 'services/website-service';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Helper } from 'resources/extensions/helper';

@inject(PageContentAreaService, EventAggregator, WebsiteService, SessionService, Helper)
export class DsTryAgainBox {
    @bindable useKeys = false;
    @bindable showStatus = false;
    @bindable main : string;
    @bindable subtitle : string;
    @bindable page : string;
    @bindable icon : string;
    @bindable href : string;
    @bindable size : number;
    @bindable alt : string;
    @bindable buttonText = 'Try again';
    @bindable variant = 'outlined';

    pageContentAreaService : PageContentAreaService;
    websiteService : WebsiteService;
    sessionService : SessionService;
    helper : Helper;
    eventAggregator : EventAggregator;
    sizeChangedSubscriber : Subscription;
    navBarSubscriber : Subscription;
    width : number;

    constructor
    (
        pageContentAreaService : PageContentAreaService,
        eventAggregator : EventAggregator,
        websiteService : WebsiteService,
        sessionService : SessionService,
        helper : Helper
    )
    {
        this.pageContentAreaService = pageContentAreaService;
        this.websiteService = websiteService;
        this.sessionService = sessionService;
        this.helper = helper;
        this.eventAggregator = eventAggregator;
    }

    async bind() {
        if (!this.useKeys || !this.page) return;
        const page = await this.websiteService.getPage(this.page);
        await this.pageContentAreaService.getByPageId(page?.id);
    }

    attached() {
        this.handleEventSubscriptions();
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    handleEventSubscriptions() {
        this.helper.resizeByNavbar(this, '#ds-try-again-box', '#navigation-bar', '100%');
        if (this.showStatus) {
            this.sizeChangedSubscriber = this.eventAggregator.subscribe('size-changed', payload => this.width = payload.width);
        }
    }
}
